import { Button, CompanyImg, Icon, Text } from "@/app/components";
import { Combobox } from "@headlessui/react";
import Highlighter from "react-highlight-words";
import { useCallback } from "react";
import { START_QUERY_AFTER, Company } from "@/app/hooks";
import { cn } from "@/lib/utils";

interface CompanyListProps {
  searchTerm: string;
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  results: Array<{
    name: string;
    id: string;
    image: string;
    websiteUrl?: string;
  }>;
  handleNavigation?: (path: string) => void;
  handleSelect?: (value: Company) => void;
  handleAddNewOpportunity?: () => void;
}

export const CompanyList = ({
  results,
  searchTerm,
  className = "",
  containerClassName = "",
  loading,
  handleSelect,
  handleAddNewOpportunity,
}: CompanyListProps) => {
  const onSelect = useCallback(
    (value) => {
      if (handleSelect) handleSelect(value);
    },
    [handleSelect]
  );

  const isEmpty =
    searchTerm.length > START_QUERY_AFTER && results.length === 0 && !loading;

  if (isEmpty) {
    return (
      <div className="px-6 py-14 text-center text-sm sm:px-14">
        <Icon type="LoopClouds" className="mx-auto h-16 w-16 text-primary" />
        <p className="mt-4 text-2xl font-semibold text-black">
          No results found
        </p>
        <p className="mt-2 text-base text-black">Please try again or</p>
        <Button
          text="Add New Opportunity"
          variant="gradient"
          size="md"
          className="mx-auto my-2 my-4 rounded-xs"
          iconRight="Add"
          onClick={handleAddNewOpportunity}
        />
      </div>
    );
  }

  return (
    <div className={`h-full w-full ${containerClassName}`}>
      <Combobox onChange={onSelect}>
        {results.length > 0 && (
          <Combobox.Options
            static
            className={cn(
              "h-full max-h-[500px] transform-gpu scroll-py-3 overflow-y-auto border-r border-neutral-200 p-3",
              className
            )}
          >
            {results.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active, selected }) =>
                  cn(
                    "mt-2 flex cursor-default cursor-pointer select-none rounded-xs p-3",
                    (active || selected) && "bg-gray-100"
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex w-full items-center">
                    <div
                      className={cn(
                        "flex h-10 w-10 flex-none items-center justify-center"
                      )}
                    >
                      <CompanyImg src={item.image} />
                    </div>
                    <div className="ml-4 w-full">
                      <p
                        className={cn(
                          "flex w-full items-center justify-between text-sm font-medium",
                          active ? "text-gray-900" : "text-gray-700"
                        )}
                      >
                        <Highlighter
                          textToHighlight={item.name}
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          highlightClassName="font-bold bg-transparent"
                        />
                        <Text
                          type="message"
                          text={item.websiteUrl}
                          className="font-[400] text-neutral-400"
                        />
                      </p>
                    </div>
                    <Icon
                      type="ArrowRight"
                      width={24}
                      height={24}
                      className={cn(
                        "ml-2 text-neutral-400",
                        active || selected ? "" : "invisible"
                      )}
                    />
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};
