import { ImageWithErrorFallback, Popover, Text } from "@/app/components";
import membersStore from "@/app/stores/members.store.tsx";
import React from "react";
import { LatestReturnProfileData } from "./use-latest-return-profile.ts";
import { ProgressArcChart } from "@/app/components/charts/progress-arc-chart.tsx";
import {
  HighlightsCard, HighlightsCardEmptyState,
  HighlightsCardFooter, HighlightsCardLastUpdated, HighlightsCardTitle
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { Summary } from "@/app/screens/opportunities/opportunity/components/summary.tsx";

type LatestReturnProfileProps = {
  data?: LatestReturnProfileData | null;
};

export const LatestReturnProfileCard = ({
  data: latestReturnProfile,
}: LatestReturnProfileProps) => {
  return (
    <HighlightsCard type={"Insight"}>
      {latestReturnProfile && (
        <>
          <HighlightsCardTitle text={String(latestReturnProfile.title)} />
          {latestReturnProfile?.multipliers && (
            <div className={"flex w-full gap-x-1 h-full"}>
              {Object.entries(latestReturnProfile.multipliers).map(
                ([label, value]) => (
                  <Popover
                    key={label}
                    containerClassName={"flex-1"}
                    childrenContent={
                      value?.responses?.length ? (
                        <div className={"space-y-2"}>
                          {value?.responses?.map(({ person }) => (
                            <div
                              className={"flex items-center gap-1"}
                              key={person}
                            >
                              <ImageWithErrorFallback
                                alt={person}
                                src={membersStore.getByName(person)?.avatar}
                                className={"h-6 w-6 rounded-xs"}
                              />
                              <Text text={person} type={"message"} />
                            </div>
                          ))}
                        </div>
                      ) : null
                    }
                  >
                    <ProgressArcChart
                      labelClassName="text-[13px]"
                      value={value.totalSentiments}
                      total={latestReturnProfile?.totalSentiments}
                      label={label}
                      options={{minSize: 54}}
                    />
                  </Popover>
                )
              )}
            </div>
          )}
          {!!latestReturnProfile.date && (
            <HighlightsCardFooter>
              <HighlightsCardLastUpdated date={latestReturnProfile.date} />
            </HighlightsCardFooter>
          )}
        </>
      )}
      {!latestReturnProfile && (
        <HighlightsCardEmptyState
          text={"No Available Return Profile"}
        />
      )}
    </HighlightsCard>
  );
};
