import toast from "react-hot-toast";

import { Card, Button, Icon, Toast } from "@/app/components";
import { EmailFeedback } from "@/app/screens/modal";
import { useSetNotificationStatusMutation } from "@/app/screens/notifications/notifications.gql.ts";
import modalStore from "@/app/stores/modal.store";

import { CompanyLink } from "../../components/company-link";
import { DateColumn } from "../../components/email-opportunity/date-column";
import { DetailsColumn } from "../../components/email-opportunity/details-column";
import { NotificationStatus } from "../../dashboard.types";

import { FeedbackEmailTemplate } from "./use-feedback-templates";

type FeedbackCardProps = {
  template: FeedbackEmailTemplate;
};

export const FeedbackCard = ({ template }: FeedbackCardProps) => {
  const { id, createdUtc, payload, status } = template;
  const [setNotificationStatus, { loading: setNotificationStatusLoading }] = useSetNotificationStatusMutation();

  const openTemplateModal = () => {
    modalStore.open(EmailFeedback, { props: payload });
  };

  const handleMarkAsDone = () => {
    setNotificationStatus({
      variables: { id, status: NotificationStatus.COMPLETED },
      refetchQueries: ["GetFeedbackTemplates", "GetUserHighlights"],
    }).then(() => {
      toast.custom((t) => (
        <Toast
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          visible={t.visible}
          title="Success"
          subTitle="Successfully marked as done"
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
    });
  };

  const isCompleted = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED].includes(
    status as NotificationStatus,
  );

  return (
    <Card key={id} className="flex flex-col items-center justify-between lg:flex-row">
      <Card.Body className="grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          <DetailsColumn styles={{ labelStyle: "hidden md:block" }}>
            <CompanyLink
              id={payload.company.id}
              name={payload.company.name}
              subTitle="Moved back to Pipeline"
              subTitleClassName="md:hidden"
            >
              <Card.Image src={payload.company?.image} className="mr-2" />
            </CompanyLink>
          </DetailsColumn>
          <DateColumn date={createdUtc} styles={{ container: "md:hidden", labelStyle: "text-xs" }} />
        </div>

        <DateColumn
          date={createdUtc}
          label="Moved back to Pipeline"
          styles={{ container: "hidden md:block col-span-4" }}
        />
        <div className="col-span-2 flex items-center justify-end gap-2 md:col-span-4">
          {!isCompleted && (
            <Button
              iconRight="Check"
              variant={"secondary"}
              size="sm"
              className="w-full bg-neutral-100 md:w-auto"
              onClick={handleMarkAsDone}
              loading={setNotificationStatusLoading}
            >
              Mark as done
            </Button>
          )}
          <Button
            variant="primary"
            className="w-full md:w-auto"
            size="sm"
            text="View Template"
            onClick={openTemplateModal}
          >
            View template
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
