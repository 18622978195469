import { useCallback, useState } from "react";
import { Icon, IconType } from "../icon/icon";
import { SearchRef, Company } from "@/app/hooks";
import { SearchBox } from ".";
import { cn } from "@/lib/utils";
import { X } from "lucide-react";

export interface SearchBarProps {
  collapse?: boolean;
  onQueryChange?: (query: string) => void;
  onReset?: () => void;
  handleSelect?: (value: Company) => void;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  iconType?: IconType;
  closeIcon?: string;
  loading?: boolean;
  selected?: string;
  queryRef?: SearchRef | null;
  clearIcon?: boolean;
}

export const SearchBar = ({
  collapse,
  className = "",
  containerClassName = "",
  inputClassName,
  iconType = "LoopGradient",
  selected,
  loading,
  queryRef,
  onQueryChange,
  handleSelect,
  onReset,
  clearIcon,
}: SearchBarProps) => {
  const [query, setQuery] = useState("");

  const handleReset = useCallback(() => {
    setQuery("");

    if (onQueryChange) onQueryChange("");
    if (onReset) onReset();
  }, [onQueryChange, onReset]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setQuery(value);

      if (onQueryChange) onQueryChange(value);
    },
    [onQueryChange],
  );

  return (
    <div className={cn("relative", containerClassName)}>
      <div className={`relative flex h-12 items-center rounded-lg border border-neutral-200 lg:h-16 ${className}`}>
        <Icon
          type={loading ? "Loader" : iconType}
          className={"absolute left-[12px] text-primary-500 md:left-4"}
          height={18}
          width={18}
        />
        {!collapse && (
          <input
            onChange={handleChange}
            value={selected || query}
            placeholder={"Search..."}
            className={cn("h-full w-full rounded-lg px-12 placeholder:text-neutral-400", inputClassName)}
          />
        )}
        {(query || selected) && (
          <button
            className="absolute right-3 cursor-pointer bg-none text-xs font-semibold text-black text-neutral-700 outline-none"
            onClick={handleReset}
          >
            {clearIcon ? <X size={"18"} color={"black"} /> : "clear"}
          </button>
        )}
      </div>
      {queryRef && (
        <SearchBox
          queryRef={queryRef}
          searchTerm={query}
          containerClassName="absolute mt-2 w-full bg-white max-h-[300px] rounded-lg shadow-card"
          handleSelect={handleSelect}
        />
      )}
    </div>
  );
};
