import React, { useState } from "react";

import { Popover as PopoverReact, PopoverContent, PopoverHandler } from "@material-tailwind/react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Direction } from "./direction";

export type PopoverPlacement =
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

type PopoverTextProps = {
  children: React.ReactNode;
  childrenContent: React.ReactNode;
  placement?: PopoverPlacement;
  containerClassName?: string;
  childrenClassName?: string;
  directionClassName?: string;
  childrenContentClassName?: string;
  alwaysOpen?: boolean;
};

export const Popover = ({
  children,
  childrenContent,
  containerClassName,
  childrenClassName,
  directionClassName,
  placement = "bottom-start",
  alwaysOpen = false,
  childrenContentClassName,
}: PopoverTextProps) => {
  const [openPopover, setOpenPopover] = useState(alwaysOpen);
  const { isBigTablet } = useBreakpoints();
  const triggers = {
    onMouseEnter: () => isBigTablet && setOpenPopover(Boolean(childrenContent) && true),
    onMouseLeave: () => !alwaysOpen && setOpenPopover(false),
    // open popover by click on mobile
    onClick: () => !isBigTablet && setOpenPopover(Boolean(childrenContent) && true),
  };

  return (
    <PopoverReact placement={placement} open={openPopover}>
      {children && (
        <PopoverHandler {...triggers}>
          <button
            className={cn(
              "m-0 flex items-center justify-center p-0 outline-none hover:bg-opacity-[/60]",
              containerClassName,
            )}
          >
            {children}
          </button>
        </PopoverHandler>
      )}
      {childrenContent && (
        <PopoverContent
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className={cn(
            "relative z-10 size-fit overflow-visible rounded-sm border-none p-3 text-black !shadow-[0_3px_10px_rgb(0,0,0,0.2)]",
            childrenClassName,
          )}
          {...triggers}
        >
          <>
            <Direction placement={placement} className={directionClassName} />
            <div className={cn("max-h-[400px] overflow-y-auto", childrenContentClassName)}>{childrenContent}</div>
          </>
        </PopoverContent>
      )}
    </PopoverReact>
  );
};
