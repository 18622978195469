import { WatchQueryFetchPolicy } from "@apollo/client";

import { LgCompanyFilter } from "@/gql/graphql.ts";

import { useGetDashboardCompaniesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { DashboardCompany, DashboardSectionData } from "../dashboard.types";

import { useVirtualQuery } from "./use-virtual-query";

export const useDashboardCompanies = (
  filter: LgCompanyFilter = {},
  fetchPolicy: WatchQueryFetchPolicy = "network-only",
): DashboardSectionData<DashboardCompany> => {
  const queryVariables = {
    filter: {
      nzrUserCompanyTagsByCompanyId: {
        none: {
          userId: { equalTo: authStore.userId },
          tags: {
            contains: {
              hide: "1",
            },
          },
        },
      },
      ...filter,
    },
    pageSize: 6,
  };

  const { data, loading, fetchMore, refetch } = useGetDashboardCompaniesQuery({
    variables: queryVariables,
    fetchPolicy,
  });

  const virtualListData = useVirtualQuery(fetchMore, refetch);

  const loadMore = () => {
    const endCursor = data?.lgCompanies?.pageInfo.endCursor;

    if (virtualListData.loadMore) {
      virtualListData.loadMore(queryVariables, endCursor);
    }
  };

  if (!data?.lgCompanies) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingMore: false,
      loadingLess: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.lgCompanies.totalCount,
    items: data.lgCompanies.edges.map(({ node }) => node),
    pageInfo: data.lgCompanies.pageInfo,
    loading: loading && !virtualListData.loadingLess,
    loadingMore: virtualListData.loadingMore,
    loadingLess: virtualListData.loadingLess,
    loadLess: virtualListData.loadLess,
    loadMore,
  };
};
