import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";

interface ProgressArcChartProps {
  value: number;
  total: number;
  label?: string;
  labelClassName?: string;
  className?: string;
  options?: {
    minSize?: number;
  };
}

const renderCustomizedLabel = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  endAngle,
  fill,
  value,
  index,
}) => {
  const circleRadius = Math.max((outerRadius - innerRadius) * 1.2, 8);
  const fontSize = Math.max(circleRadius, 12);

  // placement of the label
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * endAngle);
  const cos = Math.cos(-RADIAN * endAngle);
  const mx = cx + (outerRadius - circleRadius / 2) * cos;
  const my = cy + (outerRadius - circleRadius / 2) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1);
  const ey = my;

  // we want to display only the first one
  if (index !== 0 || !value) {
    return null;
  }

  return (
    <g transform={`translate(${ex},${ey})`}>
      <circle r={circleRadius} fill={fill} stroke="none" />
      <text
        textAnchor="middle"
        fill="#ffff"
        fontSize={fontSize}
        y={circleRadius / 2}
      >
        {value}
      </text>
    </g>
  );
};

export const ProgressArcChart = ({
  value,
  total,
  label,
  labelClassName,
  options,
  className,
}: ProgressArcChartProps) => {
  const chartData = [
    { value: value },
    // we use this part to draw a grey "background", which in fact is just a right part of the arc
    // thus we want it to take full size of the chart if value is 0
    // otherwise, it takes the remaining space
    { value: !value ? total : total - value },
  ];

  return (
    <div className={cn("relative h-full w-full", className)}>
      <ResponsiveContainer
        minWidth={options?.minSize ?? 64}
        minHeight={options?.minSize ?? 64}
      >
        <PieChart>
          <defs>
            <linearGradient id={`fillGradient`}>
              <stop offset="0%" stopColor="#E9366B" />
              <stop offset="100%" stopColor="#EE712A" />
            </linearGradient>
          </defs>
          <Pie
            dataKey="value"
            startAngle={225}
            endAngle={-45}
            data={chartData}
            cx={"50%"}
            cy={"50%"}
            innerRadius="85%"
            outerRadius="100%"
            cornerRadius="50%"
            fill={"url(#fillGradient)"}
            labelLine={false}
            label={renderCustomizedLabel}
            className="focus:outline-none focus:ring-0"
          >
            <Cell fill="url(#fillGradient)" />
            <Cell fill="#eaeaea" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {label && (
        <Text
          text={label}
          className={cn(
            labelClassName,
            "absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2"
          )}
        />
      )}
    </div>
  );
};
