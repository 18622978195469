import React from "react";

import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button, Text } from "@/app/components";
import { investmentStore, investmentFunds } from "@/app/screens/investments";
import { useRefreshMtpMutation } from "@/app/service/actions.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

// TODO: bleah, remove after MTP
const allowRefresh = [
  "iosif@localglobe.vc",
  "kira@localglobe.vc",
  "razvan@localglobe.vc",
  "ben@localglobe.vc",
  "ziv@localglobe.vc",
  "taras@localglobe.vc",
];

export const MtpHeader = observer(() => {
  const [refreshMTP, { loading }] = useRefreshMtpMutation();
  const navigate = useNavigate();
  const activeFund = investmentStore.state.activeFund;
  return (
    <div className="sticky top-0 z-10 flex flex-row items-center justify-between px-4 backdrop-blur-md">
      <div className={cn("no-scrollbar flex w-full items-center gap-x-6 overflow-auto bg-opacity-45 py-2 pl-2 pt-4")}>
        {investmentFunds.map(({ value, label }, index) => {
          const isSelected = activeFund.value === value;
          return (
            <div key={index} className={"flex w-fit items-center text-nowrap"}>
              <Text
                text={label}
                color={isSelected ? "text-neutral-900" : "text-neutral"}
                className={cn(
                  "w-fit cursor-pointer transition-all ease-in-out lg:text-[13px] 2xl:text-[14px]",
                  isSelected ? "hover:text-neutral-900" : "",
                )}
                onClick={() => {
                  navigate(`/fund-overview/${value}`);
                }}
                weight={isSelected ? "semibold" : "light"}
              />
            </div>
          );
        })}
      </div>
      <div>
        {allowRefresh.some((item) => item == authStore.user?.email) && (
          <Button
            variant={"secondary"}
            onClick={() => refreshMTP({ variables: { input: {} } })}
            disabled={loading}
            loading={loading}
            text={"Refresh Data"}
            className={"h-auto self-center bg-transparent p-0 font-light hover:text-neutral-600 active:bg-transparent"}
          />
        )}
      </div>
    </div>
  );
});
