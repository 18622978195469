import { DealroomNews, LgMember } from "@/gql/graphql.ts";

import { useFounders } from "@/app/screens/opportunities/opportunity/components/founders/use-founders.ts";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";
import membersStore from "@/app/stores/members.store.tsx";

export type CompanyDataSource = {
  name: "Dealroom" | "Trello" | "Ops Sheet" | "Nazare" | "Manual Entry" | "Sentiment Form";
  lastUpdated?: string;
};

export type CompanySectionData<TData> = {
  source?: CompanyDataSource;
  data: TData;
};

// this is going to be used for the sections where we don't have lastUpdated date
const useDataSources = (company: OpportunityCompany) => {
  const dataSources: Record<"dealroom" | "trello", CompanyDataSource> = {
    dealroom: {
      name: "Dealroom",
      lastUpdated: company?.companiesSources.items.find((item) => item.sourceType === "DealroomCompany")
        ?.dealroomCompanyAsSource?.lastUpdatedUtc,
    },
    trello: {
      name: "Trello",
      lastUpdated: company?.companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")
        ?.trelloCardAsSource?.dateLastActivity,
    },
  };

  return dataSources;
};

const useCompanyNews = (company: OpportunityCompany): CompanySectionData<DealroomNews[]> | null => {
  if (!company) {
    return null;
  }

  const dealroomData = company.companiesSources.items.find(({ sourceType }) => sourceType === "DealroomCompany");

  if (!dealroomData) {
    return null;
  }
  const news = (dealroomData.dealroomCompanyAsSource?.dealroomNewsByCompanyId.news ?? [])
    .toSorted((a, b) => a.pubDate - b.pubDate)
    .toReversed() as DealroomNews[];

  return {
    source: {
      name: "Dealroom",
      lastUpdated: dealroomData.lastUpdatedUtc,
    },
    data: news,
  };
};

// todo return type
const usePcgOverview = (company: OpportunityCompany): CompanySectionData<any> | null => {
  if (!company) {
    return null;
  }

  const opsCompanyData = company.pcgOverview.items.filter(({ opsCompanyAsSource }) => opsCompanyAsSource);

  const pcgOverview =
    opsCompanyData.reduce(
      (acc, { opsCompanyAsSource }) => {
        if (!opsCompanyAsSource) {
          return acc;
        }
        return { ...acc, ...opsCompanyAsSource };
      },
      {} as NonNullable<(typeof opsCompanyData)[number]["opsCompanyAsSource"]>,
    )?.companyFunds?.nodes || [];

  const lastUpdated = opsCompanyData
    .map((d) => d.lastUpdatedUtc)
    .sort((a, b) => b.lastUpdatedUtc - a.lastUpdatedUtc)[0];

  return {
    source: {
      name: "Ops Sheet",
      lastUpdated,
    },
    data: pcgOverview,
  };
};

export const useOpportunity = () => {
  const { company, signalAssignees } = useOpportunityContext();

  const signal = company?.badges?.[0]?.group === "signal" ? company.badges[0] : null;

  const badges = company?.badges;

  const basecampInvestors = company?.badges?.find((badge) => badge.id === "basecamp_investment");
  const pcgOverview = usePcgOverview(company);

  const portCo = company?.badges?.find((badge) => badge.id === "portfolio_company");
  const news = useCompanyNews(company);

  let assignees =
    company?.companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")?.trelloCardAsSource?.memberIds ||
    [];

  assignees = assignees
    ?.map((id) => {
      return membersStore.getByTrelloId(id);
    })
    .filter(Boolean);

  const trelloSource = company?.companiesSources?.items?.find(
    (item) => item.sourceType === "TrelloCard",
  )?.trelloCardAsSource;

  if (assignees?.length === 0) {
    assignees = (company?.signals?.items?.[0]?.asignees?.nodes || []) as LgMember[];
  }

  const founders = useFounders(company);

  const activeSectionsList: {
    id: string;
    label: string;
    visible?: boolean;
  }[] = [
    { id: "about", label: "About" },
    { id: "highlights", label: "Highlights" },
    { id: "pcg", label: "PCG" },
    { id: "founders", label: "Founders" },
    { id: "sentiment", label: "Sentiment" },
    {
      id: "performance",
      label: "Performance",
    },
    {
      id: "basecamp",
      label: "Basecamp",
      visible: company?.investors?.items.length! > 0,
    },
    { id: "funding", label: "Funding" },
    { id: "news", label: "News" },
    { id: "attachments", label: "Attachments" },
    { id: "comments", label: "Comments" },
  ].filter((section) => section.visible !== false);

  const highlightsDataSource: CompanyDataSource = {
    name: "Trello",
    lastUpdated: company?.companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")?.trelloCardAsSource
      ?.dateLastActivity,
  };

  const sentimentDataSource: CompanyDataSource = {
    name: "Nazare",
    lastUpdated: company?.sentiment.nodes
      .filter((node) => node.responses.length > 0)
      .map((node) => node.createdAt)
      .sort((a, b) => b - a)[0],
  };

  return {
    name: company?.name,
    image: company?.image,
    portCo,
    signal,
    badges,
    company,
    pcgOverview,
    basecampInvestors,
    tagline: company?.tagline,
    founders,
    assignees,
    signalAssignees,
    trelloColumn: trelloSource?.list?.name || "Not Yet Reviewed or Met",
    trelloSource,
    fundingRounds: company?.fundingRounds?.items || [],
    activeSectionsList,
    news,
    highlightsDataSource,
    sentimentDataSource,
    dataSources: useDataSources(company),
  };
};

// todo duplicated in other components
export const useSocialMediaIcons = (company: OpportunityCompany) => {
  const trelloSource = company?.companiesSources?.items?.find(
    (item) => item.sourceType === "TrelloCard",
  )?.trelloCardAsSource;

  return [
    { name: "Continent", url: company?.websiteUrl },
    { name: "Twitter", url: company?.twitterUrl },
    { name: "Linkedin", url: company?.linkedinUrl },
    { name: "Trello", url: trelloSource?.url },
    {
      name: "Dealroom",
      url: company?.companiesSources?.items.reduce((webLink, source) => {
        if (source.dealroomCompanyAsSource?.url) {
          return webLink + source.dealroomCompanyAsSource?.url;
        }
        return webLink;
      }, ""),
    },
  ];
};
