import { cn } from "@/lib/utils.ts";

import { JumpToTop } from "@/app/components";
import { PageNavProvider } from "@/app/components/page-nav/page-nav-provider.tsx";
import { PageNav } from "@/app/components/page-nav/page-nav.tsx";
import { Sentiment } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment.tsx";
import {
  OpportunityProvider,
  useOpportunityContext,
} from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import {
  Attachments,
  BasecampInfo,
  Comments,
  FundingHistory,
  MetricsTable,
  News,
  OpportunityHeader,
  PCGOverview,
  PerformanceChart,
  SectionTitle,
} from "./components";
import { Founders } from "./components/founders/founders.tsx";
import { Highlights } from "./components/highlights/highlights.tsx";
import { OpportunityHeaderSticky } from "./components/opportunity-header-sticky.tsx";
import { Section } from "./components/section.tsx";
import { useOpportunity } from "./use-opportunity.tsx";

const Opportunity = () => {
  const {
    company,
    signal,
    founders,
    pcgOverview,
    activeSectionsList,
    fundingRounds,
    basecampInvestors,
    portCo,
    news,
    highlightsDataSource,
    sentimentDataSource,
    dataSources,
    // comments,
  } = useOpportunity();

  const { viewMode } = useOpportunityContext();

  if (!company) {
    return null;
  }

  return (
    <div className="flex h-fit w-full flex-col" id={"scroller"}>
      <OpportunityHeaderSticky company={company} />
      <div
        className={cn(
          "relative grid h-fit w-full grid-cols-1 space-y-8 px-5 py-4 lg:space-y-16 lg:px-[2.5%] lg:py-6",
          viewMode === "contrast" ? "bg-neutral-300" : "bg-[#F1F1F1]",
        )}
      >
        <Section id="about" className="!gap-0">
          <OpportunityHeader />
        </Section>

        <Section id="highlights">
          <SectionTitle title={"Highlights"} source={highlightsDataSource} />
          <Highlights company={company} signal={signal} portCo={portCo} />
        </Section>

        {Boolean(pcgOverview?.data.length) && (
          <Section id="pcg">
            <SectionTitle title={"Phoenix Court Group Overview"} source={pcgOverview?.source} />
            <PCGOverview pcgOverview={pcgOverview?.data} />
          </Section>
        )}

        <Section id={"founders"}>
          <SectionTitle title={"Founders"} source={founders.source} />
          <Founders founders={founders.data} />
        </Section>

        <Section id={"sentiment"}>
          <SectionTitle title={"Sentiment"} source={sentimentDataSource} />
          <Sentiment />
        </Section>

        <Section id={"performance"}>
          <SectionTitle title={"Performance History"} source={dataSources.dealroom} />
          <PerformanceChart company={company} />
          <MetricsTable company={company} />
        </Section>

        {basecampInvestors && (
          <Section id={"basecamp"}>
            <SectionTitle title={"Basecamp"} source={dataSources.dealroom} />
            <BasecampInfo company={company} />
          </Section>
        )}

        <Section id="funding">
          <SectionTitle title={"Funding History"} source={dataSources.dealroom} />
          <FundingHistory fundingRounds={fundingRounds} />
        </Section>

        <Section id="news">
          <SectionTitle title={"Related News"} source={news?.source} />
          <News news={news?.data} />
        </Section>

        <Section id="attachments">
          <SectionTitle title={"Attachments"} />
          <Attachments />
        </Section>

        <Section id="comments">
          <SectionTitle title={"Comments"} />
          <Comments />
        </Section>

        <div className={"mt-8 h-2 select-none text-background"}>
          <div>{"easter egg"}</div>
        </div>
        <PageNav navLinks={activeSectionsList} />
      </div>
      <JumpToTop hideOn={["about"]} />
    </div>
  );
};

export const OpportunityView = () => {
  return (
    <OpportunityProvider>
      <PageNavProvider>
        <Opportunity />
      </PageNavProvider>
    </OpportunityProvider>
  );
};
