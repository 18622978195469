import { useState } from "react";

import type { ButtonColorType } from "@/app/components/button";
import { useForm } from "@/app/hooks";
import { useSnooze } from "@/app/screens/modal/opportunities/hooks/useSnooze.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

import { SnoozeForm } from "../components";

import { useResurface } from "./use-resurface";

export enum SnoozeActionType {
  Snooze = "Snooze",
  Pipeline = "Pipeline",
}

export interface UseSnoozeModalParams {
  id: string;
  actionType?: SnoozeActionType;
  onSuccess?: () => void;
}

const snoozeSteps = [
  {
    index: 0,
    title: "Move Back to Pipeline",
    description: "",
    mainAction: "Next",
    secondaryAction: "Cancel",
    showBack: false,
    primaryBtnVariant: "gradient" as ButtonColorType,
  },
  {
    index: 1,
    title: "Have you let the company know we won't be progressing at this stage?",
    description:
      "Haven't informed them yet? No problem. Nazare will prepare a template note for you to send based on the team's past interactions with the company",
    mainAction: "Yes I've let them know",
    secondaryAction: "Not Yet",
    showBack: false,
  },
  {
    index: 2,
    title: "Nazare will generate a template note based on the team’s past interactions with the company. ",
    description:
      "If there are specific details or reasons for not proceeding with the company that you wish to emphasize, please provide them below.",
    mainAction: "Move to Pipeline & Generate Template Note",
    secondaryAction: "Cancel",
    primaryBtnVariant: "gradient" as ButtonColorType,
  },
];

export const useSnoozeOptions = ({ id, actionType = SnoozeActionType.Snooze, onSuccess }: UseSnoozeModalParams) => {
  const [step, setStep] = useState(0);
  const form = useForm(actionType);
  const { ...actions } = useSnooze(form, id, onSuccess);
  const { resurfaceOption, handleResurfacePeriodChange } = useResurface();
  const valid = step === 0 ? Boolean(!form.values()?.reason?.length || !form.values()?.resurface?.length) : false;

  const handleSubmit = actions.onSubmit;

  const mainActionFn = () => {
    if (!step) {
      return setStep((prevState) => prevState + 1);
    }

    return handleSubmit();
  };

  const secondaryActionFn = () => {
    if (step === 1) {
      form.$("feedback_email").set("");
      return setStep((prevState) => prevState + 1);
    }
    return modalStore.close();
  };

  return {
    ...actions,
    handleSubmit,
    form,
    step: {
      ...snoozeSteps[step],
      mainActionFn,
      secondaryActionFn,
      valid,
    },
    setStep,
    RenderForm: (
      <SnoozeForm
        form={form}
        step={snoozeSteps[step]}
        resurfaceOption={resurfaceOption}
        handleResurfacePeriodChange={handleResurfacePeriodChange}
      />
    ),
  };
};
