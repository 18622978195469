import { useState } from "react";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";

import { CompaniesForwardedItem } from "./companies-forwarded-item.tsx";
import { useCompaniesForwarded } from "./use-companies-forwarded.ts";

export const CompaniesForwardedSection = () => {
  const [showArchived, setShowArchived] = useState(false);
  const data = useCompaniesForwarded(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.companiesForwarded.id}
      title={dashboardSections.companiesForwarded.label}
      count={data.totalCount}
      emptyPlaceholder={{ enabled: data.totalCount === 0 && !data.loading, message: "No companies forwarded" }}
      loading={data.loading}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      loadMoreBtn={{
        enabled: !!data.pageInfo?.hasNextPage,
        onLoadMore: data.loadMore,
        loading: data.loadingMore,
      }}
      loadLessBtn={{
        enabled: !!data.pageInfo?.hasPreviousPage,
        onLoadLess: data.loadLess,
        loading: data.loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {data.items.map((item) => (
          <CompaniesForwardedItem key={item.id} notification={item} />
        ))}
      </div>
    </DashboardSection>
  );
};
