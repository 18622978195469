import { useState } from "react";

import { SentimentRequestItem } from "@/app/screens/dashboard/sections/sentiment-form-requests/sentiment-request-item.tsx";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";

import { useSentimentFormRequests } from "./use-sentiment-form-requests.ts";

export const SentimentRequestsSection = () => {
  const [showArchived, setShowArchived] = useState(false);
  const { items, totalCount, pageInfo, loading, loadingLess, loadingMore, loadLess, loadMore } =
    useSentimentFormRequests(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.sentimentRequests.id}
      title={dashboardSections.sentimentRequests.label}
      count={totalCount}
      loading={loading}
      emptyPlaceholder={{ enabled: totalCount === 0 && !loading, message: "No sentiment forms to complete" }}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      loadMoreBtn={{
        enabled: pageInfo?.hasNextPage,
        onLoadMore: loadMore,
        loading: loadingMore,
      }}
      loadLessBtn={{
        enabled: pageInfo?.hasPreviousPage,
        onLoadLess: loadLess,
        loading: loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {items.map((item) => (
          <SentimentRequestItem key={item.id} data={item} archived={showArchived} />
        ))}
      </div>
    </DashboardSection>
  );
};
