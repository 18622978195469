import { useMemo } from "react";

import { cn } from "@/lib/utils";

import { Text, Tooltip, Icon } from "@/app/components";
import { BaseToggle } from "@/app/components/select-controls";
import { FlagType, useFlagMutation } from "@/app/hooks";
import { getCompanyFlags } from "@/app/misc/parse-company-helpers";
import membersStore from "@/app/stores/members.store";
import { Company } from "@/app/types";

import { getFlagStatus } from "./utils/use-flag-status";

export interface CompanyFlagToggleProps {
  company: Pick<Company, "id" | "companyFlags">;
  flag?: FlagType;
  label?: string;
  showDetails?: boolean;
  styles?: {
    container?: string;
    labelStyle?: string;
    toggleContainer?: string;
    detailsStyle?: string;
  };
}

export const CompanyFlagToggle = ({
  company,
  showDetails,
  flag = "sharing_allowed",
  styles = {},
  label = "Share with Network",
}: CompanyFlagToggleProps) => {
  const companyFlags = getCompanyFlags(company);

  const { container, labelStyle, toggleContainer, detailsStyle } = styles;
  const { onSubmit, loading } = useFlagMutation({});
  const { isFlagged, distance, userId } = getFlagStatus(companyFlags[flag]);

  const details = useMemo(() => {
    if (userId && distance) {
      const user = membersStore.getById(userId);

      return user ? `${user.firstName} enabled sharing ${distance}` : "";
    }

    return "";
  }, [distance, userId]);

  const handleToggle = async () => onSubmit({ companyId: company.id, flag, toggle: !isFlagged });

  const toggleLabel = useMemo(() => {
    if (!showDetails && details) {
      return (
        <Tooltip text={details} placement="top">
          <Text type="subtitle" className={cn("mb-1.5 flex items-center font-[500] text-neutral-900", labelStyle)}>
            {label}
            <Icon type="Info" className="ml-1 size-4" />
          </Text>
        </Tooltip>
      );
    }

    return <Text type="subtitle" text={label} className={cn("mb-1.5 font-[500] text-neutral-900", labelStyle)} />;
  }, [details, label, labelStyle, showDetails]);

  return (
    <div className={cn("ml-auto", container)}>
      {toggleLabel}
      <div className={cn("flex space-x-4", toggleContainer)}>
        <BaseToggle value={isFlagged} disabled={loading} onChange={handleToggle} leftLabel="Yes" rightLabel="No" />
      </div>
      {showDetails && <Text type="body" text={details} className={detailsStyle} />}
    </div>
  );
};
