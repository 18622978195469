import React, { useMemo } from "react";

import { PcgTable } from "./pcg-table.tsx";

export const PCGOverview = ({ pcgOverview }) => {
  const { data, totals } = useMemo(() => {
    const data =
      pcgOverview.map((companyFund) => ({
        fund: companyFund.fund.name,
        type: companyFund.investmentType,
        initialInvestment: companyFund.initialInvestment,
        goingInOwnership: companyFund.goingInOwnership,
        totalInvestedCapital: companyFund.totalInvestedCapital,
        investedCapitalPercentage: companyFund.investedCapitalFromFund,
        // totalInvestedCapital / (evAsFundReturner * 1_000_000 * currentOwned),
        unrealisedNav: companyFund.unrealisedValue,
        realisedNav: companyFund.currentNav,
        vc: companyFund.currentMultiple,
        currentOwned: companyFund.currentOwned,
        evAsFundReturner: companyFund.evAsFundReturner * 1_000_000,
        currentEv: companyFund.mtpEv || companyFund.currentEv * 1_000_000,
        currentNavPercentage: companyFund.currentNavFromFund,
      })) || [];

    const totals = {
      initialInvestment: data.reduce((acc, { initialInvestment }) => {
        if (Number.isNaN(initialInvestment)) {
          return acc;
        }
        return acc + initialInvestment;
      }, 0),
      totalInvested: data.reduce((acc, { totalInvested }) => {
        if (Number.isNaN(totalInvested)) {
          return acc;
        }
        return acc + totalInvested;
      }, 0),
      unrealisedNav: data.reduce((acc, { unrealisedNav }) => {
        if (Number.isNaN(unrealisedNav)) {
          return acc;
        }
        return acc + unrealisedNav;
      }, 0),
    };

    return { data, totals };
  }, [pcgOverview]);

  return (
    <div className="overflow-x-auto rounded-lg bg-white">
      <PcgTable data={data} totals={totals} />
    </div>
  );
};
