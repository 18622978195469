import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetFeedbackTemplatesQuery, useGetFeedbackTemplatesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import { useVirtualQuery } from "../../hooks/use-virtual-query";

export type FeedbackEmailTemplate = NonNullable<GetFeedbackTemplatesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useFeedbackTemplates = (showArchived: boolean): DashboardSectionData<FeedbackEmailTemplate> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    statuses: showArchived ? [NotificationStatus.COMPLETED] : [NotificationStatus.SEEN, NotificationStatus.UNSEEN],
    pageSize: 3,
  };

  const { data, loading, fetchMore, refetch } = useGetFeedbackTemplatesQuery({
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const virtualListData = useVirtualQuery(fetchMore, refetch);

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;

    if (virtualListData.loadMore) {
      virtualListData.loadMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !virtualListData.loadingLess,
    loadingLess: virtualListData.loadingLess,
    loadingMore: virtualListData.loadingMore,
    loadLess: virtualListData.loadLess,
    loadMore,
  };
};
