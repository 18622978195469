import * as React from "react";

import { useGoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router";

import { cn } from "@/lib/utils";

import { Button } from "@/app/components";
import { useLoginMutation } from "@/app/screens/login/login.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

type UserAuthFormProps = React.HTMLAttributes<HTMLDivElement>;

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [actionMutation] = useLoginMutation();
  const { state } = useLocation();
  const next = state?.next ?? "/pipeline";

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      try {
        // const response = await loginWithGoogle(tokenResponse.code);
        const { data, errors } = await actionMutation({
          variables: { code: tokenResponse?.code },
        });

        if (errors) {
          throw new Error("Bad auth request");
        }

        authStore.expiresAt = data?.loginWithGoogle?.expiresAt;
        authStore.accessToken = data?.loginWithGoogle?.accessToken;
        authStore.refreshToken = data?.loginWithGoogle?.refreshToken;
        authStore.login(data?.loginWithGoogle?.user);

        setTimeout(() => {
          navigate(next);
          setIsLoading(false);
        }, 100);
      } catch (e) {
        setIsLoading(false);
      }
    },
    onError: (errorResponse) => {
      setIsLoading(false);
    },
    onNonOAuthError: (nonOAuthError) => {
      setIsLoading(false);
    },
  });

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        navigate("/pipeline");
      }, 100);
    }, 3000);
  }

  return (
    <div className={cn("flex flex-col gap-6", className)} {...props}>
      <Button
        variant={"outline"}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          googleLogin();
        }}
        loading={isLoading}
        text={"Sign in with Google"}
        className={"self-center"}
      />
    </div>
  );
}
