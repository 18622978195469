import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetForwardedCompaniesQuery, useGetForwardedCompaniesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { useVirtualQuery } from "../../hooks/use-virtual-query";

export const newItemStatuses = [NotificationStatus.UNSEEN];
const completedStatuses = [NotificationStatus.SEEN, NotificationStatus.COMPLETED, NotificationStatus.DECLINED];

type ForwardedCompanyItemData = NonNullable<GetForwardedCompaniesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useCompaniesForwarded = (showArchived: boolean): DashboardSectionData<ForwardedCompanyItemData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 3,
    statuses: showArchived ? completedStatuses : newItemStatuses,
  };

  const { data, loading, fetchMore, refetch } = useGetForwardedCompaniesQuery({
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const virtualListData = useVirtualQuery(fetchMore, refetch);

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;

    if (virtualListData.loadMore) {
      virtualListData.loadMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !virtualListData.loadingLess,
    loadingLess: virtualListData.loadingLess,
    loadingMore: virtualListData.loadingMore,
    loadMore,
    loadLess: virtualListData.loadLess,
  };
};
