import React from "react";

import { useFormContext } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Text, TextArea } from "@/app/components";
import { FormControl, FormField, FormItem, FormMessage } from "@/app/components/charts/form-field.tsx";
import { CompanyFlagToggle } from "@/app/screens/opportunities/opportunity/components/toggle-flags";

import { FormSelect } from "../input";

type RatingSelectProps = {
  setSelected: (value: number) => void;
  selected: number;
  properties: {
    labels: {
      left: string;
      right: string;
    };
  };
};

const RatingSelect = React.forwardRef<HTMLDivElement, RatingSelectProps>(
  ({ setSelected, selected, properties }, ref) => {
    return (
      <div className="flex flex-col" ref={ref}>
        {properties.labels && (
          <div className="mb-2 flex justify-between">
            <span className="text-xs text-neutral md:text-sm">{properties.labels.left}</span>
            <span className="text-xs text-neutral md:text-sm">{properties.labels.right}</span>
          </div>
        )}
        <div className="flex w-full justify-between gap-x-2">
          {[...Array(11).keys()].map((idx) => (
            <Text
              key={idx}
              text={String(idx)}
              onClick={() => setSelected(idx)}
              data-cy="rating-select"
              className={cn(
                "flex size-8 cursor-pointer items-center justify-center rounded-sm bg-neutral-100 text-center text-[14px] hover:bg-neutral-200 hover:text-black lg:size-14 lg:text-[20px]",
                {
                  "bg-neutral-800 text-white hover:bg-neutral-800 hover:text-white": selected == idx,
                },
              )}
            />
          ))}
        </div>
      </div>
    );
  },
);

RatingSelect.displayName = "RatingSelect";

export const SentimentInputFormField = ({ field, visible, clearError }) => {
  const { control } = useFormContext();

  return (
    <div className={`mb-6 flex flex-col space-y-4 ${visible ? "" : "hidden"}`}>
      <div className="flex">
        <p className="flex text-sm font-semibold leading-5 tracking-tight lg:whitespace-normal lg:text-base">
          {field.title}
          {field.validations?.required && <span className={"ml-1 text-red"}>*</span>}
        </p>
      </div>
      <div>
        {(field.type === "dropdown" || field.type === "multiple_choice") && (
          <FormField
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              const isMultiSelect = field.type === "multiple_choice" && field.properties.allow_multiple_selection;
              const allOptions = field.properties.choices.map((choice) => ({
                label: choice.label,
                value: choice.label,
              }));

              return (
                <FormItem
                  ref={(elem) => {
                    ref({
                      ...elem,
                      focus: () => elem?.scrollIntoView({ block: "start" }),
                    });
                  }}
                >
                  <FormControl onBlur={onBlur}>
                    <FormSelect
                      options={allOptions}
                      multiSelect={isMultiSelect}
                      defaultOptions={(value || []).map((val) => ({
                        value: val,
                        label: val,
                      }))}
                      onChange={(value = []) => {
                        onChange(value.map((value) => value.value));
                        if (clearError) clearError(field.field_id);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
            name={field.field_id}
          />
        )}
        {(field.type === "rating" || field.type === "opinion_scale") && (
          <FormField
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <FormItem
                  ref={(elem) => {
                    ref({
                      ...elem,
                      focus: () => elem?.scrollIntoView({ block: "start" }),
                    });
                  }}
                >
                  <FormControl>
                    <RatingSelect
                      properties={field.properties}
                      setSelected={(val) => {
                        onChange(val);
                        if (clearError) clearError(field.field_id);
                      }}
                      selected={value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
            name={field.field_id}
          />
        )}
        {(field.type === "short_text" || field.type === "text") && (
          <FormField
            name={field.field_id}
            control={control}
            render={({ field: props }) => (
              <FormItem>
                <FormControl>
                  <TextArea
                    {...props}
                    value={props.value || ""}
                    onChange={(text) => {
                      props.onChange({ target: { value: text } });
                      if (clearError) clearError(field.field_id);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {field.type === "toggle_flag" && (
          <div className="flex justify-center">
            <CompanyFlagToggle
              company={field.company}
              flag={field.flag}
              showDetails
              styles={{
                container: "mx-auto min-h-40",
                toggleContainer: "flex justify-center my-4",
                detailsStyle: "text-xs text-black font-[500]",
              }}
              label={field.subTitle}
            />
          </div>
        )}
      </div>
    </div>
  );
};
