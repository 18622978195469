import { DashboardSectionData } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetSentimentFormRequestsQuery, useGetSentimentFormRequestsQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { useVirtualQuery } from "../../hooks/use-virtual-query";

export type SentimentFormData = NonNullable<
  GetSentimentFormRequestsQuery["nzrSentimentForms"]
>["edges"][number]["node"];

export const useSentimentFormRequests = (showArchive: boolean): DashboardSectionData<SentimentFormData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 3,
    completed: showArchive,
  };

  const { data, loading, fetchMore, refetch } = useGetSentimentFormRequestsQuery({
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const virtualListData = useVirtualQuery(fetchMore, refetch);

  const loadMore = () => {
    const endCursor = data?.nzrSentimentForms?.pageInfo.endCursor;

    if (virtualListData.loadMore) {
      virtualListData.loadMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrSentimentForms) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrSentimentForms.totalCount,
    items: data.nzrSentimentForms.edges.map(({ node }) => node),
    pageInfo: data.nzrSentimentForms.pageInfo,
    loading: loading && !virtualListData.loadingLess,
    loadingLess: virtualListData.loadingLess,
    loadingMore: virtualListData.loadingMore,
    loadLess: virtualListData.loadLess,
    loadMore,
  };
};
